import React from "react"
import PropTypes from "prop-types"
import Layout from '../components/layout'
// import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

// Components
{/*
<li key={path}>
  <Link to={path}>{title}</Link>
</li>*/}

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } tagged with "${tag}"`

  return (
    <Layout>
      <h1>{tagHeader}</h1>
      <Link to="/tags">All tags</Link>
      <ul>
        {edges.map(({ node }) => {
          const { path, title, author, date } = node.frontmatter
          return (
            <div style={{ margin: `1% auto`, maxWidth: 650, padding: `0 1rem` }} key={ path }>
              <a href={ path} className="art-container">
                <Img className="preview-image" sizes={node.frontmatter.featuredImage.childImageSharp.sizes} />
                <div className="img-overlay">
                  <h3>{title}</h3>
                  <small>Posted by {author} on {date}</small>
                </div>
              </a>
            </div>
          )
        })}
      </ul>
      {/*
              This links to a page that does not yet exist.
              We'll come back to it!
            */}
    </Layout>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              path: PropTypes.string.isRequired,
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            author
            date
            path
            featuredImage {
                childImageSharp{
                    sizes(maxWidth: 630) {
                        ...GatsbyImageSharpSizes
                    }
                }
            }
          }
        }
      }
    }
  }
`
